import Vue from "vue"
import "normalize.css/normalize.css" // A modern alternative to CSS resets
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import "@/styles/index.scss" // global css
import App from "./App"
// import apiCommon from "./api/common"
import jsCookie from 'js-cookie'
import store from "./store"
import router from "./router"
import "@/icons" // icon
import "@/permission" // permission control
import "@/components"
import VueQuillEditor from "vue-quill-editor"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
import test from "@/utils/test"
import * as utils from "./utils/index"
import publicfuc from './utils/publicMethods'
import VueClipboard from 'vue-clipboard2'
import VueMarquee from 'vue-marquee-component-fix';
import commonApi from "@/api/common";
import echarts from 'echarts'
import Print from 'vue-print-nb'
Vue.use(Print)
Vue.prototype.$echarts = echarts
    // import VueSocketIO from "vue-socket.io";
Vue.prototype.$cookie = jsCookie;
Vue.use(VueClipboard)
Vue.use(VueQuillEditor)
Vue.use(ElementUI)
Vue.use(commonApi)
Vue.use(VueMarquee);

// Vue.use(
//     new VueSocketIO({
//         debug: true,
//         connection: process.env.VUE_APP_HOST_IO,
//         autoConnect: false,
//         options: {
//             query: "loginUserNum="
//         },
//     })
// )


Vue.config.productionTip = false
Vue.prototype.$test = test
Vue.prototype.$utils = utils
Vue.prototype.publicfuc = publicfuc

new Vue({
    el: "#app",
    router,
    store,
    render: (h) => h(App),
})