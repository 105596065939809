<template>
  <div class="app-container">
    <app-toolbar v-bind="toolbar" @create="onCreate" @update="onUpdate" @remove="onRemove"></app-toolbar>
    <app-list
      ref="list"
      v-bind="{ remote, props, rowClickable }"
      @selection-change="onSelectionChange"
    />
  </div>
</template>

<script>
export default {
  name: "AppListPage",
  props: {
    // 远程获得数据的API
    remote: Object,
    // 表格显示的属性列表
    props: Array,
    // 是否可行单击
    rowClickable: Boolean,
  },
  data() {
    return {
      // 选中的对象列表
      selected: [],
    };
  },
  computed: {
    // 工具栏按钮禁用状态
    toolbar() {
      const { length } = this.selected;
      const have = length > 0;
      return {
        update: have,
        remove: have,
      };
    },
  },
  methods: {
    // 表格选中的项列表
    onSelectionChange(e) {
      this.selected = e;
    },
    // 新增路由
    async onCreate() {
      const path = `${this.$route.path}/create`;
      await this.$router.push(path);
    },
    // 更新路由
    async onUpdate() {
      const [{ id }] = this.selected;
      const path = `${this.$route.path}/update`;
      await this.$router.push({ path, query: { id } });
    },
    // 删除项
    async onRemove() {
      try {
        await this.$confirm("此操作将永久删除该记录，是否继续？", "提示", {
          type: "warm",
        });
        const data = this.selected.map((item) => item.id);
        await this.remote.remove(data);
        await this.$notify({
          title: "成功",
          message: "已删除",
          type: "success",
        });
        await this.$refs.list.getItems();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
