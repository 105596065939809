<template>
  <div class="appListWrap">
    <div class="list">
      <el-row :gutter="20" class="m-b-10">
        <el-col :span="4" v-if="btnFlag.addShow"
          ><div class="">
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="showAdd"
              size="small"
              >新增</el-button
            >
            <el-button
              icon="el-icon-delete"
              @click="handleDel"
              v-if="btnFlag.delShow && selecTionArr.length > 0"
              size="small"
              >批量删除</el-button
            >
          </div></el-col
        >
        <el-col :span="btnFlag.addShow ? 20 : 24"
          ><div class="">
            <slot name="subSlot" :selecTionArr="selecTionArr"></slot></div
        ></el-col>
        <el-col :span="24"
          ><div class=""><slot name="searchSlot"></slot></div
        ></el-col>
      </el-row>

      <el-table
        ref="appList"
        :data="data"
        border
        highlight-current-row
        row-key="id"
        @cell-click="cellClick"
        @selection-change="getSelection($event)"
      >
        <el-table-column
          v-if="isSelectShow"
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in props"
          :key="index"
          v-bind="item"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span
              v-if="item.type == 'html'"
              v-html="item.formatter(scope.row)"
            ></span>

            <span v-else-if="item.type && item.type == 'image'">
              <el-image
                class="tablePic"
                :src="`api/${scope.row[item.prop]}`"
                :preview-src-list="`api/${scope.row[item.prop]}`.split(' ')"
              ></el-image>
            </span>

            <span v-else-if="item.type && item.type == 'operationLink'">
              <template v-for="(v, i) in item.formatter(scope.row)">
                <el-link
                  :style="{
                    margin: item.formatter(scope.row).length > 1 ? '0 5px' : ''
                  }"
                  :icon="v.icon"
                  :key="i"
                  :type="v.type"
                  v-if="v.show"
                  :underline="false"
                  @click="handlerType(v)"
                  >{{ v.text }}</el-link
                >
              </template>
            </span>

            <span v-else-if="item.type && item.type == 'switch'">
              <template>
                <el-switch
                  v-model="scope.row[item.prop]"
                  :active-value="0"
                  :inactive-value="1"
                  active-color="#13ce66"
                  inactive-color="#ccc"
                  @change="item.formatter().handler(scope.row)"
                >
                </el-switch>
              </template>
            </span>
            <span v-else>{{ handlerFormatter(item, scope.row) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        v-bind="pageInfo"
        :layout="layout"
        :page-sizes="[15, 50, 100, 200]"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
      <!--    模态框-->
      <el-dialog
        :title="formType === 'create' ? '新增' : '编辑'"
        center
        :visible.sync="dialogVisible"
        width="1000px"
        :close-on-click-modal="false"
      >
        <el-form
          :label-width="labelWidth"
          ref="form"
          label-position="right"
          :model="form"
          :rules="rules"
          ><el-row :gutter="20">
            <el-col
              :span="item.span ? item.span : 8"
              v-for="(item, index) in formProps"
              :key="index"
            >
              <el-form-item
                :label="item.label"
                :prop="item.prop"
                :rules="item.rules"
              >
                <el-input
                  v-if="item.type == 'input'"
                  v-model="form[item.prop]"
                  v-bind="item"
                  size="small"
                ></el-input>

                <el-input
                  v-if="item.type == 'number'"
                  v-model.number="form[item.prop]"
                  v-bind="item"
                  size="small"
                ></el-input>

                <el-input
                  v-if="item.type == 'textarea'"
                  type="textarea"
                  :rows="3"
                  v-model="form[item.prop]"
                >
                </el-input>

                <!-- 下拉框 -->
                <el-select
                  filterable
                  v-if="item.type == 'select'"
                  v-model="form[item.prop]"
                  size="small"
                  v-bind="item"
                  class="inlineFormSelect"
                  @change="$emit('selectChange', $event, item.prop)"
                >
                  <el-option
                    v-for="(items, indexs) in item.selectData"
                    :key="indexs"
                    :label="items.label"
                    :value="items.value"
                  ></el-option>
                </el-select>
                <!--层级 -->
                <el-cascader
                  class="fullWidth"
                  v-if="item.type == 'cascader'"
                  v-model="form[item.prop]"
                  :options="item.options"
                  size="small"
                  :props="{ checkStrictly: true, emitPath: false }"
                  @change="$emit('cascaderChange', $event, item.prop)"
                  clearable
                ></el-cascader>
                <!-- 日期 -->
                <el-date-picker
                  :style="{ width: '270px' }"
                  v-if="item.type == 'date'"
                  v-model="form[item.prop]"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
                <!-- 单选 -->
                <el-radio-group
                  v-if="item.type == 'radio'"
                  v-model="form[item.prop]"
                  size="small"
                >
                  <el-radio-button
                    v-for="(items, indexs) in item.selectData"
                    :key="indexs"
                    :label="items.value"
                    >{{ items.label }}</el-radio-button
                  >
                </el-radio-group>

                <!-- 图片上传 -->

                <el-upload
                  v-if="item.type == 'upload'"
                  :action="action"
                  list-type="picture-card"
                  :on-success="handlePictureSuccess"
                  :on-remove="handleRemove"
                  :file-list="getFileList(form[item.prop])"
                  :on-progress="setUploadProp(item.prop)"
                  :limit="1"
                  :on-exceed="handlePictureExceed"
                >
                  <i class="el-icon-plus"></i>
                  <div slot="tip" class="el-upload__tip">
                    {{ item.notice }}
                  </div>
                </el-upload>
                <!-- 富文本 -->
                <Editor
                  v-if="item.type == 'editor'"
                  :style="{ height: '100%' }"
                  v-model="form[item.prop]"
                  :propCon="form[item.prop]"
                  @postContent="getContent"
                ></Editor>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <span slot="footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSave">确定</el-button>
        </span>
      </el-dialog>
      <el-dialog :visible.sync="operationVisible">
        <el-table
          ref="table"
          :data="selecTionArr"
          border
          highlight-current-row
          row-key="id"
          @row-dblclick="showEdit($event)"
        >
          <el-table-column
            v-for="(item, index) in props"
            :key="index"
            v-bind="item"
            :width="item.width"
          >
            <template slot-scope="scope">
              <span v-if="item.type && item.type == 'image'">
                <el-image
                  class="tablePic"
                  :src="`api/${scope.row[item.prop]}`"
                  :preview-src-list="`api/${scope.row[item.prop]}`.split(' ')"
                ></el-image>
              </span>
              <span v-else-if="item.type && item.type == 'operation'">
                <span v-for="(v, i) in item.formatter(scope.row)" :key="i">
                  <el-button :type="v.type" @click="v.handler" :size="v.size">{{
                    v.text
                  }}</el-button>
                </span>
              </span>
              <span v-else>{{ handlerFormatter(item, scope.row) }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth';

export default {
  name: 'AppList',
  props: {
    remote: { type: Object, default: null },
    buttonShow: { type: Boolean, default: true },
    isSelectShow: { type: Boolean, default: true },
    showDialogContent: { type: Boolean, default: false },
    isInline: { type: Boolean, default: false },
    apiName: { type: String, default: '' },

    editTitle: { type: String, default: '' },
    labelWidth: { type: String, default: '80px' },
    searchQuery: { type: Object, default: null },
    rules: { type: Object, default: null }, //applist接收表单验证
    searchControl: {
      type: Object,
      default: () => {
        return {
          firstSoltSpan: [10, 9, 8, 5],
          secondSoltSpan: [0, 0, 0, 0],
          thirdSoltSpan: [14, 15, 16, 19]
        };
      }
    },
    btnFlag: {
      type: Object,
      default: () => {
        return {
          addShow: true,
          delShow: true
        };
      }
    },
    addBtnText: { type: String, default: '新增' },
    editBtnText: { type: String, default: '编辑' },
    deleteBtnText: { type: String, default: '删除' },

    props: {
      type: Array,
      default: () => {
        return [];
      }
    },
    formProps: {
      type: Array,
      default: () => {
        return [];
      }
    },
    rowClickable: Boolean,
    formatterColor: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      action: '/api/file/upload',
      value: 1,
      curUploadProp: '',
      dialogVisiblePic: false,
      operationVisible: false,
      showContentVisible: false,
      pageInfo: {
        currentPage: 1,
        pageCount: 1,
        pageSize: 15,
        total: 0,
        apiName: this.apiName
      },
      selecTionArr: [],
      formType: 'create',
      form: {},
      dialogVisible: false,
      data: [],
      nowFieldName: '',
      layout: 'sizes, prev, pager, next, jumper',
      headers: {
        token: getToken()
      }
    };
  },
  async created() {
    // await this.getItems();
  },
  watch: {
    searchQuery: {
      handler() {
        console.log('dddfff');
        // this.pageInfo = Object.assign({}, this.pageInfo, val);
        this.getItems();
      },
      immediate: true,
      deep: true //开启深度监听
    }
  },
  methods: {
    getFileList(url) {
      const prefix = '/api/';
      let arr = [];
      if (url) {
        arr.push({ name: 'pic', url: prefix + url });
      }
      return arr;
    },
    handlePictureSuccess(response) {
      this.form[this.curUploadProp] = response.data.path;
    },
    handleRemove(file) {
      if (file.status == 'success') {
        this.form[this.curUploadProp] = '';
      }
    },
    handlePictureExceed() {
      this.$message.error('超过上传数量！');
    },

    setUploadProp(str) {
      this.curUploadProp = str;
    },

    // 手动开关 ——>是否推荐
    async handleSwitch(e) {
      console.log(e);
    },

    handleSave() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          let obj = Object.assign(
            {},
            { apiName: this.apiName + '/' + this.formType },
            this.form
          );

          let res = await this.remote.commonPost(obj);
          if (res.code == 200) {
            this.$message.success(res.message);
            this.getItems();
            this.dialogVisible = false;
          }
        } else {
          return false;
        }
      });
    },

    async getItems() {
      let obj = Object.assign({}, this.searchQuery, this.pageInfo);
      await this.remote.list(obj).then(res => {
        this.data = res.data.data;
        this.pageInfo.total = parseInt(res.data.total);
      });
    },
    getContent(val) {
      this.form.content = val;
    },
    showAdd() {
      this.formType = 'create';
      this.form = {};
      //这里遍历一次数据，检查是否存在默认值

      this.formProps.forEach(element => {
        if (element.default || element.default == 0) {
          this.form[element.prop] = element.default;
        }
      });

      this.dialogVisible = true;
    },

    async showEdit(e) {
      const loading = this.$loading();
      this.formType = 'update';
      const { data } = await this.remote.detail({
        apiName: this.apiName,
        id: e.id
      });
      this.form = data;
      loading.close();
      this.dialogVisible = true;
    },

    async handleDel() {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let ids = [];

          this.selecTionArr.forEach(item => {
            ids.push(item.id);
          });
          const query = {
            ids: ids,
            apiName: this.apiName
          };
          await this.remote.remove(query).then(res => {
            if (res.code == 200) {
              this.$message({ message: res.message, type: 'success' });
            } else {
              this.$message(res.message);
            }

            this.getItems();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    async handelRevoke() {
      let ids = [];

      this.selecTionArr.forEach(item => {
        ids.push(item.id);
      });
      const query = {
        ids: ids,
        apiName: this.apiName
      };
      await this.remote.revoke(query).then(res => {
        if (res.code == 200) {
          this.$message({ message: res.message, type: 'success' });
        } else {
          this.$message(res.message);
        }

        this.getItems();
      });
    },
    getSelection(e) {
      this.selecTionArr = e;
    },
    async onSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getItems();
    },
    async onPageChange(e) {
      this.pageInfo.currentPage = e;
      this.getItems();
    },
    handlerFormatter(item, row) {
      if (item.formatter) {
        return item.formatter(row);
      } else {
        return row[item.prop];
      }
    },

    handlerType(row) {
      if (row.handlerType == 'update') {
        this.showEdit(row.data);
      } else if (row.handlerType == 'remove') {
        this.selecTionArr = [row.data];
        this.handleDel();
      } else {
        row.handler();
      }
    },
    cellClick(row, column) {
      this.$emit('cellClick', { row, column });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .ql-snow .ql-editor img {
  max-width: 100%;

  vertical-align: top;
  outline-width: 0px;
}
.fullWidth {
  width: 100% !important;
  overflow: hidden;
}
.mb20 {
  margin-bottom: 20px;
}
.autoWidth {
  width: calc(100% - 80px);
}
.el-form-item.is-required .el-form-item__label:before {
  content: none !important;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.inlineForm .inlineFormSelect {
  width: 270px;
}

.inlineForm .inlineFormInput {
  width: 270px;
}
.quill-editor {
  margin-bottom: 20px;
}
.el-form--inline .el-form-item {
  width: 48%;
}
.upload_info {
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba($color: #000, $alpha: 0.2);
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 270px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 260px;
  height: 178px;
  display: block;
}

.toolWrap {
  padding: 20px 0;
}

.footerBtn {
  text-align: center;
}
.list {
  display: flex;
  flex-direction: column;

  .el-table {
    overflow: auto;
  }

  .el-pagination {
    padding: 0;
    padding-top: 24px;
  }

  .indent .cell {
    margin-left: 23px;
  }
}

/deep/ .el-form-item {
  margin-bottom: 5px;
}
.tablePic {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  /deep/ .el-image-viewer__close {
    color: #fff;
    opacity: 1;
  }
}
.green {
  color: green;
}
.red {
  color: red;
}
/deep/ .el-form-item__error {
  top: 80%;
}
.editor /deep/ .ql-editor.ql-blank {
  min-height: 300px;
}
/deep/ .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
