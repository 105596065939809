// 用户管理
import request from "@/utils/request"

export default {
	login(data) {
		return request.post("/user/login", data)
	},
	logout() {
		return request.post("/user/logout")
	},
	list(params) {
		return request.get("/user", { params })
	},
	detail(params) {
		return request.get("/user/getLoginUserInfo ", { params })
	},
	create(data) {
		return request.post("/user/create", data)
	},
	update(data) {
		return request.post("/user/update", data)
	},
	remove(data) {
		return request.post("/user/remove", data)
	},
}
