<template>
  <div class="navbar">
    <sidebar-logo />

    <!-- favicon.ico -->
    <div class="right-menu">
      <el-dropdown class="avatar-container">
        <div class="avatar-wrapper">
          <img class="user-avatar" src="favicon.ico" alt="avatar" />
          <span class="user-name">{{ userInfo.name }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item>
            <router-link to="/">
              <i class="el-icon-user"></i>
              <span>返回主页</span>
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="pswDialogVisible = true">
            <i class="el-icon-setting"></i>
            <span>修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <i class="el-icon-switch-button"></i>
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      :title="'重置密码'"
      :visible="pswDialogVisible"
      :modal-append-to-body="false"
      :show-close="false"
      width="400px"
      center
    >
      <div class="changeCon">
        <div style="margin-top: 10px">
          <el-input
            placeholder="请输入旧密码"
            v-model="oldPassword"
            type="passw"
            style="margin-bottom: 10px"
          >
          </el-input>
          <el-input
            placeholder="请输入新密码"
            v-model="password"
            type="passw"
            style="margin-bottom: 10px"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pswDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleChangePassWord"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarLogo from "./Sidebar/SidebarLogo";
import api from "@/api/common";
export default {
  components: {
    SidebarLogo,
  },
  data() {
    return {
      pswDialogVisible: false,
      password: "",
      oldPassword: "",
      notice: "",
      rollWidth: 0,
      rollShow: false,
    };
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "userInfo"]),
  },
  watch: {},
  mounted() {},
  methods: {
    async handleChangePassWord() {
      if (this.password.length < 6) {
        this.$message.error("密码长度不得低于6位");
        return false;
      } else {
        let res = await api.commonPost({
          apiName: "/user/updatePassWord",
          password: this.password,
          oldPassword: this.oldPassword,
        });
        if (res.code == 200) {
          this.$message.success("重置密码成功");
          this.pswDialogVisible = false;
        }
      }
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login`);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 70px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    // line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 55px;
      height: 100%;

      .avatar-wrapper {
        height: 100%;
        display: flex;
        position: relative;
        align-items: center;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .user-name {
          font-size: 16px;
        }
        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
        }
      }
    }
  }
}
</style>
