import user from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    userInfo: undefined
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  }
};

const actions = {
  // user login
  async login({ commit }, userInfo) {
    const { username, password } = userInfo;
    const response = await user.login({
      username: username.trim(),
      password: password
    });
    const { data } = response;
    commit('SET_TOKEN', data.token);
    commit('SET_NAME', '管理员');
    setToken(data.token);
  },

  async userInfo({ commit }) {
    const { data } = await user.detail();
    commit('SET_USERINFO', data);
    return data;
  },

  // user logout
  async logout({ commit }) {
    await user.logout();
    removeToken();
    resetRouter();
    commit('RESET_STATE');
  },

  // remove token
  resetToken({ commit }) {
    removeToken();
    commit('RESET_STATE');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
