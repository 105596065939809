export default {
    formatDate(secs, type = 0) {
        //type是可选参数，因为json中时间的格式是year-month-date,不能识别XX年XX月XX日
        var t = new Date(secs);
        var year = t.getFullYear();
        var month = t.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }
        var date = t.getDate();
        if (date < 10) {
            date = "0" + date;
        }
        var hour = t.getHours();
        if (hour < 10) {
            hour = "0" + hour;
        }
        var minute = t.getMinutes();
        if (minute < 10) {
            minute = "0" + minute;
        }
        var second = t.getSeconds();
        if (second < 10) {
            second = "0" + second;
        }
        if (type == 0) {
            return year + "年" + month + "月" + date + "日";
        } else if (type == 2) {
            return (
                year +
                "-" +
                month +
                "-" +
                date +
                " " +
                hour +
                ":" +
                minute +
                ":" +
                second
            );
        } else if (type == 5) {
            return (
                year +
                "-" +
                month +
                "-" +
                date +
                "&&" +
                hour +
                ":" +
                minute +
                ":" +
                second
            );
        } else if (type == 3) {
            return hour + ":" + minute + ":" + second;
        } else if (type == 4) {
            return year + "-" + month + "-" + date;
        } else if (type == 6) {

            var todayArr = [new Date(year + '-' + month + '-' + date + ' ' + 0 + ':' + 0 + ':' + 0), new Date(year + '-' + month + '-' + date + ' ' + 23 + ':' + 59 + ':' + 59)]
            return todayArr
        } else {
            return year + "-" + month + "-" + date + " " + hour + "时";
        }
    },
    //浮点型加减乘除

    numberMul(arg1, arg2) {
        var m = 0;
        var s1 = arg1.toString();
        var s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length;
        } catch (e) { console.log(e); }
        try {
            m += s2.split(".")[1].length;
        } catch (e) { console.log(e); }

        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    },

    numberAdd(arg1, arg2) {
        var r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2))
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m + arg2 * m) / m).toFixed(n);
    },

    numberSub(arg1, arg2) {
        var re1, re2, m, n;
        try {
            re1 = arg1.toString().split(".")[1].length;
        } catch (e) {
            re1 = 0;
        }
        try {
            re2 = arg2.toString().split(".")[1].length;
        } catch (e) {
            re2 = 0;
        }
        m = Math.pow(10, Math.max(re1, re2));
        n = (re1 >= re2) ? re1 : re2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    // 除数，被除数， 保留的小数点后的位数
    numberDiv(arg1, arg2, digit) {
        var t1 = 0,
            t2 = 0,
            r1, r2;
        try { t1 = arg1.toString().split(".")[1].length } catch (e) { console.log(e); }
        try { t2 = arg2.toString().split(".")[1].length } catch (e) { console.log(e); }
        r1 = Number(arg1.toString().replace(".", ""))
        r2 = Number(arg2.toString().replace(".", ""))
            //获取小数点后的计算值
        var result = ((r1 / r2) * Math.pow(10, t2 - t1)).toString()
        var result2 = result.split(".")[1];
        result2 = result2.substring(0, digit > result2.length ? result2.length : digit);

        return Number(result.split(".")[0] + "." + result2);
    },
    jumpToPage(name, query, router) {

        router.push({
            path: name,
            query: query,
        });
    },
};