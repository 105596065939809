module.exports = {
    title: '商城管理系统',

    /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
    fixedHeader: true,
    tagsView: true,
    /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
    sidebarLogo: true
};