<template>
  <div class="app-container">
    <el-form ref="form" v-bind="{ model, rules }" label-width="auto">
      <el-form-item
        v-for="(value, name, index) in model"
        :key="index"
        v-bind="descriptors[index]"
      >
        <el-input
          v-if="type(index, ['text', 'textarea'])"
          v-model="model[name]"
          v-bind="descriptors[index]"
        />
        <el-select
          v-else-if="type(index, ['select'])"
          v-model="model[name]"
          v-bind="descriptors[index]"
        >
          <el-option
            v-for="(item, j) in descriptors[index].options"
            :key="j"
            v-bind="item"
          />
        </el-select>
        <el-date-picker
          v-else-if="type(index, 'date-picker')"
          v-model="model[name]"
          v-bind="descriptors[index]"
        />
        <el-time-select
          v-else-if="type(index, 'time-select')"
          v-model="model[name]"
          v-bind="descriptors[index]"
        />
        <el-cascader
          v-else-if="type(index, 'cascader')"
          v-model="model[name]"
          v-bind="descriptors[index]"
        />
        <strong v-else>{{ model[name] }}</strong>
      </el-form-item>
      <el-form-item v-if="fileExplorer" label="会议文件">
        <file-explorer />
      </el-form-item>
      <el-form-item>
        <el-button v-if="!detail" type="primary" @click="onSubmit">
          提交
        </el-button>
        <el-button @click="$router.back()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'AppFormPage',
  props: {
    // 获得远程数据的 CURD API
    remote: Object,
    // 表单绑定的模型
    model: Object,
    // 表单校验
    rules: Object,
    // 表单项描述
    descriptors: Array,
    // 是否更新方式
    update: Boolean,
    // 是否详情展示
    detail: Boolean,
    // 是否显示文件管理器
    fileExplorer: Boolean
  },
  data() {
    return {
      id: this.$route.query.id
    };
  },
  async created() {
    const {
      update,
      detail,
      getItem,
      getSelectOptoins,
      getCascaderOptions
    } = this;
    if (update || detail) {
      await getItem();
    }
    if (!detail) {
      await getSelectOptoins();
      await getCascaderOptions();
    }
  },
  methods: {
    async getItem() {
      const { remote, id, model } = this;
      const { data } = await remote.detail({ id });
      for (const key in model) {
        model[key] = data[key];
      }
    },
    async getSelectOptoins() {
      const select = this.descriptors.find(item => item.type == 'select');
      if (!select) {
        return;
      }
      const { action } = select;
      const {
        data: { data }
      } = await action({ pageSize: 999 });
      select.options = data.map(item => {
        return { label: item.name, value: item.id };
      });
    },
    async getCascaderOptions() {
      const cascader = this.descriptors.find(item => item.type == 'cascader');
      if (!cascader) {
        return;
      }
      const { action } = cascader;
      const { data } = await action({ pageSize: 999 });
      cascader.options = data;
    },
    async onSubmit() {
      const { update, remote, model, id, $router, $notify } = this;
      await this.$refs.form.validate();
      let message = '';
      if (update) {
        message = '已更新';
        await remote.update(Object.assign(model, { id }));
      } else {
        message = '已添加';
        await remote.create(model);
      }
      await $notify({
        title: '成功',
        message,
        type: 'success'
      });
      await $router.back();
    },
    type(index, types) {
      const { detail, descriptors } = this;
      return !detail && types.includes(descriptors[index].type);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form {
  margin: 20px;
  width: 600px;

  .el-date-editor,
  .el-select,
  .el-cascader {
    width: 100%;
  }
}
</style>
