
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */

export function getDateByNum(date) {
	var num = date
	let n = num
	let d = new Date()
	let year = d.getFullYear()
	let mon = d.getMonth() + 1
	let day = d.getDate()
	if (day <= n) {
		if (mon > 1) {
			mon = mon - 1
		} else {
			year = year - 1
			mon = 12
		}
	}
	d.setDate(d.getDate() - n)
	year = d.getFullYear()
	mon = d.getMonth() + 1
	day = d.getDate()
	let s =
		year +
		"-" +
		(mon < 10 ? "0" + mon : mon) +
		"-" +
		(day < 10 ? "0" + day : day)
	return s
}
export function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null
	}
	const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}"
	let date
	if (typeof time === "object") {
		date = time
	} else {
		if (typeof time === "string" && /^[0-9]+$/.test(time)) {
			time = parseInt(time)
		}
		if (typeof time === "number" && time.toString().length === 10) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay(),
	}
	const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
		const value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === "a") {
			return ["日", "一", "二", "三", "四", "五", "六"][value]
		}
		return value.toString().padStart(2, "0")
	})
	return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
	if (("" + time).length === 10) {
		time = parseInt(time) * 1000
	} else {
		time = +time
	}
	const d = new Date(time)
	const now = Date.now()

	const diff = (now - d) / 1000

	if (diff < 30) {
		return "刚刚"
	} else if (diff < 3600) {
		// less 1 hour
		return Math.ceil(diff / 60) + "分钟前"
	} else if (diff < 3600 * 24) {
		return Math.ceil(diff / 3600) + "小时前"
	} else if (diff < 3600 * 24 * 2) {
		return "1天前"
	}
	if (option) {
		return parseTime(time, option)
	} else {
		return (
			d.getMonth() +
			1 +
			"月" +
			d.getDate() +
			"日" +
			d.getHours() +
			"时" +
			d.getMinutes() +
			"分"
		)
	}
}
function toType(obj) {
	return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
// 移除对象空值
export function filterNull(o) {
	for (var key in o) {
		if (o[key] === null || o[key] === '' || o[key] === undefined) {
			delete o[key]
		}
		if (toType(o[key]) === 'string') {
			o[key] = o[key].trim()
		} else if (toType(o[key]) === 'object') {
			o[key] = filterNull(o[key])
		} else if (toType(o[key]) === 'array') {
			o[key] = filterNull(o[key])
		}
	}
	return o
}
/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
	const search = url.split("?")[1]
	if (!search) {
		return {}
	}
	return JSON.parse(
		'{"' +
		decodeURIComponent(search)
			.replace(/"/g, '\\"')
			.replace(/&/g, '","')
			.replace(/=/g, '":"')
			.replace(/\+/g, " ") +
		'"}'
	)
}

/**
 * 判断是否为空
 */
export function isEmpty(value) {
	switch (typeof value) {
		case "undefined":
			return true
		case "string":
			if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, "").length == 0)
				return true
			break
		case "boolean":
			if (!value) return true
			break
		case "number":
			if (0 === value || isNaN(value)) return true
			break
		case "object":
			if (null === value || value.length === 0) return true
			for (var i in value) {
				return false
			}
			return true
	}
	return false
}
export function getDay(day, num = 0) {
	let myDate = new Date() //获取今天日期
	myDate.setDate(myDate.getDate() - day + num)
	let dateArray = []
	let dateTemp
	let flag = 1
	for (var i = 0; i < day; i++) {
		dateTemp = myDate.getMonth() + 1 + "-" + myDate.getDate()
		dateArray.push(dateTemp)
		myDate.setDate(myDate.getDate() + flag)
	}
	return dateArray
}

export function getBase64(file) {
	return new Promise(function (resolve, reject) {
		let reader = new FileReader()
		let imgResult = ''
		reader.readAsDataURL(file)
		reader.onload = function () {
			imgResult = reader.result
		}
		reader.onerror = function (error) {
			reject(error)
		}
		reader.onloadend = function () {
			resolve(imgResult)
		}
	})
}
export function exportExcel(res, title) {
	let blob = new Blob([res])
	let url = window.URL.createObjectURL(blob)
	let link = document.createElement("a")
	link.download = `${title}.xlsx`
	link.href = url
	link.click()
}
//跨域下载文件
export function downloadFile(url, zipName) {
	url = url.replace(/\\/g, "/")
	const xhr = new XMLHttpRequest()
	xhr.open("GET", url, true)
	xhr.responseType = "blob"
	// 文件下载进度
	xhr.onprogress = (res) => {
		this.loadingTip =
			"源文件下载中: " + ((res.loaded / res.total) * 100).toFixed(2) + "%"
	}
	xhr.onload = () => {
		this.loadingTip = ""
		this.loading = false

		if (xhr.status === 200) {
			// 获取文件blob数据并保存

			var export_blob = new Blob([xhr.response])
			var save_link = document.createElementNS(
				"http://www.w3.org/1999/xhtml",
				"a"
			)
			save_link.href = URL.createObjectURL(export_blob)
			save_link.download = zipName

			save_link.click()
		}
	}

	xhr.send()
}
