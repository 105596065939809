import { asyncRoutes, constantRoutes } from '@/router';
import Layout from '@/layout';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role));
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = [];

  routes.forEach(route => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

function getRouters(values) {
  const routers = values.map(item => {
    const name = item.name;
    const path = item.path;

    const children = item.child.map(it => {

      return {
        name: it.name,
        path: it.path,
        hidden:it.hidden==0?false:true,
        component: () => import(`../../views${it.component}`),
        meta: { title: it.title, icon: it.icon }
      };
    });
    return {
      name,
      path,
      hidden:item.hidden==0?false:true,
      component: Layout,
      meta: {
        title: item.title,
        icon: item.icon
      },
      children
    };
  });
  return routers.concat(asyncRoutes);
}

const state = {
  routes: [],
  addRoutes: []
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};

const actions = {
  generateRoutes({ commit }, access) {
    return new Promise(resolve => {
      const accessedRoutes = getRouters(access);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
