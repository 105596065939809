<template>
  <div class="app-container">
    <div class="list">
      <div class="toolWrap flex">
        <div style="max-height: 40px; padding: 4px 0">
          <el-button
            size="small"
            type="success"
            v-if="buttonShow"
            icon="el-icon-plus"
            @click="showAdd"
            >新增</el-button
          >
          <el-button
            size="small"
            type="primary"
            v-if="buttonShow"
            :disabled="selecTionArr.length !== 1"
            icon="el-icon-edit"
            @click="showEdit(selecTionArr[0])"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="danger"
            v-if="buttonShow"
            :disabled="selecTionArr.length == 0"
            icon="el-icon-delete"
            @click="handelDel"
            >删除</el-button
          >
        </div>

        <div class="soltWrap flex-2">
          <slot name="tool"></slot>
        </div>
      </div>
      <el-table
        ref="table"
        :data="data"
        border
        highlight-current-row
        row-key="id"
        @selection-change="getSelection($event)"
        @row-dblclick="showEdit($event)"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in props"
          :key="index"
          v-bind="item"
          :width="item.width"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <span v-if="item.type && item.type == 'image'">
              <el-image
                class="tablePic"
                :src="`api/${scope.row[item.prop]}`"
                :preview-src-list="`api/${scope.row[item.prop]}`.split(' ')"
              ></el-image>
            </span>
            <span v-else-if="item.type && item.type == 'operation'">
              <template v-for="(v, i) in item.formatter(scope.row)">
                <el-button :type="v.type" @click="v.handler" :key="i">{{
                  v.text
                }}</el-button>
              </template>
            </span>
            <span
              v-else-if="
                formatterColor &&
                  item.prop == 'screenStatus' &&
                  (scope.row.screenStatus == 0 || scope.row.screenStatus == 1)
              "
              :class="[scope.row.screenStatus == 0 ? 'red' : 'green']"
              >{{ handlerFormatter(item, scope.row) }}</span
            >

            <span v-else>{{ handlerFormatter(item, scope.row) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        v-bind="pageInfo"
        :layout="layout"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
      <!--    模态框-->
      <el-dialog
        :title="formType === 'new' ? '新增-' + editTitle : '编辑-' + editTitle"
        center
        :visible.sync="dialogVisible"
        :width="dialogWidth"
        :close-on-click-modal="false"
      >
        <el-form label-width="80px" :inline="true" class="inlineForm">
          <el-form-item
            v-for="(item, index) in formProps"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            :rules="item.rules"
            :class="item.isFull ? 'fullWidth' : ''"
          >
            <el-input
              class="inlineFormInput"
              v-if="item.type == 'input'"
              v-model="form[item.prop]"
              :style="{ width: '260px' }"
            ></el-input>
            <!-- 下拉框 -->
            <el-select
              filterable
              v-if="item.type == 'select'"
              v-model="form[item.prop]"
              class="inlineFormSelect"
            >
              <el-option
                v-for="(items, indexs) in item.selectData"
                :key="indexs"
                :label="items.label"
                :value="items.value"
              ></el-option>
            </el-select>
            <!--层级 -->
            <el-cascader
              v-if="item.type == 'cascader'"
              class="inlineFormSelect"
              v-model="form[item.prop]"
              :options="item.selectData"
              :props="{ checkStrictly: true, emitPath: false }"
              clearable
            ></el-cascader>
            <!-- 日期 -->
            <el-date-picker
              :style="{ width: '260px' }"
              v-if="item.type == 'date'"
              v-model="form[item.prop]"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <!-- 单选 -->
            <el-radio-group
              v-if="item.type == 'radio'"
              v-model="form[item.prop]"
            >
              <el-radio
                v-for="(items, indexs) in item.selectData"
                :key="indexs"
                :label="items.value"
                >{{ items.label }}</el-radio
              >
            </el-radio-group>
            <!-- 图片上传 -->
            <el-upload
              v-if="item.type == 'upload'"
              class="avatar-uploader"
              action="/api/file/upload"
              :headers="{ token }"
              :on-success="handleSuccess"
              :show-file-list="false"
            >
              <img
                v-if="form[item.prop]"
                :src="`api/${form[item.prop]}`"
                class="avatar"
                @click="setPicField(item.prop)"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                @click="setPicField(item.prop)"
              ></i>
            </el-upload>
            <!-- 富文本 -->
            <Editor
              v-if="item.type == 'editor'"
              :style="{ height: '100%' }"
              v-model="form[item.prop]"
              :propCon="form[item.prop]"
              @postContent="getContent"
            ></Editor>
            <!-- 多行输入 -->
            <app-multi-line
              v-if="item.type == 'multi-line'"
              :props="item.props"
              v-model="form[item.prop]"
            />
          </el-form-item>
          <el-form-item class="footerBtn">
            <el-button
              v-if="formType == 'new'"
              type="primary"
              @click="handelAdd()"
              >提交</el-button
            >
            <el-button
              v-if="formType == 'edit'"
              type="primary"
              @click="handelEdit()"
              >提交</el-button
            >
            <el-button @click="dialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableList',
  props: {
    remote: { type: Object, default: null },
    buttonShow: { type: Boolean, default: true },
    apiName: { type: String, default: '' },
    editTitle: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    searchQuery: { type: Object, default: null },
    dialogWidth: { type: String, default: '800px' },
    props: {
      type: Array,
      default: () => {
        return [];
      }
    },
    formProps: {
      type: Array,
      default: () => {
        return [];
      }
    },
    rowClickable: Boolean,
    formatterColor: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisiblePic: false,
      pageInfo: {
        currentPage: 1,
        pageCount: 1,
        pageSize: 10,
        total: 0
      },
      selecTionArr: [],
      formType: 'new',
      form: {},
      dialogVisible: false,
      data: [],
      nowFieldName: '',
      layout: 'sizes, prev, pager, next, jumper'
    };
  },
  computed: {
    token() {
      return this.$store.state.user.token;
    }
  },
  async created() {
    await this.getItems();
  },
  watch: {
    searchQuery: {
      handler(val) {
        this.pageInfo = Object.assign(this.pageInfo, val);
        this.getItems();
      },
      deep: true //开启深度监听
    }
  },
  methods: {
    test(data) {
      console.log('data', data);
    },
    setPicField(str) {
      this.nowFieldName = str;
    },
    handleSuccess(file) {
      this.$set(this.form, this.nowFieldName, file.data.path);
    },
    async getItems() {
      this.pageInfo.apiName = this.apiName;
      console.log(this.pageInfo);
      await this.remote.list(this.pageInfo).then(res => {
        this.data = res.data.data;
        this.pageInfo.total = parseInt(res.data.total);
      });
    },
    getContent(val) {
      this.form.content = val;
    },
    showAdd() {
      this.formType = 'new';
      this.form = {};
      // 特例，多行输入，必须有第一行
      const item = this.formProps.find(item => item.type == 'multi-line');
      if (item) {
        this.$set(this.form, 'commodityList', []);
        this.form.commodityList.push({
          commoditTypeId: '',
          commoditName: '',
          detectProjectId: '',
          quantity: '',
          detectResult: ''
        });
      }
      this.dialogVisible = true;
    },
    async handelAdd() {
      this.form.apiName = this.apiName;
      await this.remote.create(this.form).then(res => {
        if (res.code == 200) {
          this.$message({ message: res.message, type: 'success' });
        } else {
          this.$message(res.message);
        }
        this.dialogVisible = false;
        this.getItems();
      });
    },
    showEdit(e) {
      console.log(e);
      this.formType = 'edit';
      this.form = e;
      console.log(this.form);
      this.dialogVisible = true;
    },
    async handelEdit() {
      this.form.apiName = this.apiName;
      await this.remote.update(this.form).then(res => {
        if (res.code == 200) {
          this.$message({ message: res.message, type: 'success' });
        } else {
          this.$message(res.message);
        }
        this.dialogVisible = false;
        this.getItems();
      });
    },
    async handelDel() {
      let ids = [];

      this.selecTionArr.forEach(item => {
        ids.push(item.id);
      });
      const query = {
        ids: ids,
        apiName: this.apiName
      };
      await this.remote.remove(query).then(res => {
        if (res.code == 200) {
          this.$message({ message: res.message, type: 'success' });
        } else {
          this.$message(res.message);
        }

        this.getItems();
      });
    },
    getSelection(e) {
      this.selecTionArr = e;
    },
    async onSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getItems();
    },
    async onPageChange(e) {
      this.pageInfo.currentPage = e;
      this.getItems();
    },
    handlerFormatter(item, row) {
      if (item.formatter) {
        return item.formatter(row);
      } else {
        return row[item.prop];
      }
    },
    handlerFormatterColor(item, row) {
      console.log('sdfsdfdsf---<', item.formatter(row));
      console.log('row12312312312---<', row);
    }
  }
};
</script>

<style lang="scss" scoped>
.fullWidth {
  width: 100% !important;
  overflow: hidden;
}
.el-form-item.is-required .el-form-item__label:before {
  content: none !important;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.inlineForm .inlineFormSelect {
  width: 260px;
}

.inlineForm .inlineFormInput {
  width: 260px;
}
.quill-editor {
  margin-bottom: 20px;
}
.el-form--inline .el-form-item {
  width: 48%;
}
.upload_info {
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba($color: #000, $alpha: 0.2);
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 260px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 260px;
  height: 178px;
  display: block;
}

.toolWrap {
  padding: 20px 0;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.soltWrap {
  display: inline-block;
  margin-left: 10px;
}
.footerBtn {
  text-align: center;
}
.list {
  display: flex;
  flex-direction: column;

  .el-table {
    overflow: auto;
  }

  .el-pagination {
    padding: 0;
    padding-top: 24px;
  }

  .indent .cell {
    margin-left: 23px;
  }
}
.tablePic {
  width: 120px;
  height: 80px;
  border-radius: 5px;
}
.green {
  color: green;
}
.red {
  color: red;
}
.editor /deep/ .ql-editor.ql-blank {
  min-height: 300px;
}
</style>
