<template>
  <div class="searchBar m-b-10">
    <el-row :gutter="10">
      <el-col
        v-for="(item, index) in props"
        :key="index"
        :span="item.span ? item.span : 4"
      >
        <div class="item" v-if="item.type == 'input'">
          <el-input
            :placeholder="item.placeholder"
            v-model="searchQuery[item.prop]"
            class="fullWidth"
            size="small"
          >
            <template slot="prepend">{{ item.label }}</template>
          </el-input>
        </div>

        <div class="item withoutBorder" v-if="item.type == 'select'">
          <span
            :style="{ width: item.labelWidth ? item.labelWidth : '70px' }"
            >{{ item.label }}</span
          >
          <el-select
            :style="{
              width: item.labelWidth
                ? 'calc(100% - ' + item.labelWidth + ')'
                : 'calc(100% - 70px)'
            }"
            v-model="searchQuery[item.prop]"
            filterable
            :placeholder="item.placeholder"
            size="small"
          >
            <el-option
              v-for="(itemc, index) in item.selectData"
              :key="index"
              :label="itemc.label"
              :value="itemc.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="4">
        <el-button size="small" @click="reSet">重置</el-button>
        <el-button size="small" type="primary" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="showWrite"
          size="small"
          type="warning"
          @click="$emit('write')"
        >
          核销
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    showWrite: Boolean,
    props: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      searchQuery: {}
    };
  },
  methods: {
    reSet() {
      this.searchQuery = {};
      this.$emit('searchChange', {});
    },
    handleSearch() {
      let obj = {};
      for (const key in this.searchQuery) {
        const element = this.searchQuery[key];
        obj[key] = element;
      }
      this.$emit('searchChange', obj);
    }
  }
};
</script>

<style lang="scss" scoped>
.item span {
  display: inline-block;
  text-align: center;
  background-color: #f5f7fa;
  color: #909399;
  vertical-align: middle;
  line-height: 32px;
  height: 32px;
  padding: 0 10px;
  border: 1px solid #dcdfe6;
  border-right: none;
  position: relative;
  top: -1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 14px;
}

.withoutBorder {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    top: -1px;
  }
}

.fullWidth {
  width: 100% !important;
  overflow: hidden;
}
</style>
