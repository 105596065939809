import Vue from "vue"

import AppToolbar from "./AppToolbar"
import AppListPage from "./AppListPage"
import AppList from "./AppList"
import SearchBar from "./SearchBar"
import TableList from "./TableList"
import AppFormPage from "./AppFormPage"
import AppMultiLine from "./AppMultiLine"
import Editor from "./common/Editor"

Vue.component(AppToolbar.name, AppToolbar)
Vue.component(AppList.name, AppList)
Vue.component(SearchBar.name, SearchBar)
Vue.component(TableList.name, TableList)
Vue.component(AppFormPage.name, AppFormPage)
Vue.component(AppListPage.name, AppListPage)
Vue.component(AppMultiLine.name, AppMultiLine)
Vue.component(Editor.name, Editor)