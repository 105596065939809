<template>
  <div>
    <quill-editor
      class="editor"
      v-model="content"
      ref="myQuillEditor"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @change="onEditorChange($event)"
    ></quill-editor>
  </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
export default {
  name: "Editor",
  components: {
    quillEditor,
  },
  props: {
    propCon: { type: String, default: "" },
  },
  watch: {
    propCon: function () {
      this.resetcontent();
    },
  },
  created() {
    this.resetcontent();
  },

  data() {
    return {
      content: null,
      editorOption: {},
    };
  },
  methods: {
    resetcontent() {
      this.content = this.propCon;
    },
    onEditorBlur() {},
    onEditorFocus() {
      //获得焦点事件
    },
    onEditorChange() {
      //内容改变事件
      this.$emit("postContent", this.content);
    },
  },
};
</script>
 
<style>
.ql-editor {
  height: 300px !important;
}
</style>