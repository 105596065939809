// 会议管理
import request from "@/utils/request"
import FileSaver from "file-saver"

export default {
    commonGet(params) {
        return request.get(params.apiName, { params })
    },

    commonGetDiy(params) {

        return request.get(params.url, {...params })
    },
    //如果是post形式，config要写在第三参数，但是如果是get，则写在第二参数                                                                                 
    commonPost(data) {
        return request.post(data.apiName, data, data)
    },
    list(params) {
        return request.get(params.apiName, { params })
    },
    create(data) {
        return request.post(data.apiName + "/create", data)
    },
    detail(params) {
        return request.get(params.apiName + "/detail", { params })
    },
    update(data) {
        return request.post(data.apiName + "/update", data)
    },
    remove(data) {
        return request.post(data.apiName + "/remove", data)
    },
    revoke(data) {
        return request.post(data.apiName + "/revoke", data)
    },
    // 下载文件
    async download(params) {
        const result = await request.get(params.apiName, {
            responseType: "blob",
            params,
            raw: true,
        })
        const fileName = result.headers["content-disposition"].match(
            /filename=(.*)/i
        )[1]
        const blob = new Blob([result.data], {
            type: "application/octet-stream",
        })
        FileSaver.saveAs(blob, decodeURIComponent(fileName))
    },


}