<template>
  <div class="app-toolbar">
    <el-upload
      v-if="upload"
      class="upload"
      :action="action"
      :show-file-list="false"
      :headers="{ method: 'POST' }"
      :on-progress="onProgress"
      :on-success="onSuccess"
      :on-error="onError"
    >
      <el-button>导入</el-button>
    </el-upload>
    <el-button-group>
      <el-button type="primary" :disabled="!create" @click="$emit('create')">
        新增
      </el-button>
      <el-button type="warning" :disabled="!update" @click="$emit('update')">
        更新
      </el-button>
      <el-button type="danger" :disabled="!remove" @click="$emit('remove')">
        删除
      </el-button>
    </el-button-group>
    <div class="toolWrap"><slot name="tool"></slot></div>
    
  </div>
</template>

<script>
export default {
  name: 'AppToolbar',
  props: {
    upload: Boolean,
    action: String,
    create: {
      type: Boolean,
      default: true
    },
    update: Boolean,
    remove: Boolean
  },
  data() {
    return {
      loading: null
    };
  },
  methods: {
    // 正在上传
    onProgress() {
      this.loading = this.$loading({ target: '.app-main' });
    },
    // 上传成功
    onSuccess() {
      this.$emit('upload-success');
      this.loading.close();
    },
    // 上传错误
    onError(err) {
      this.$message({
        showClose: true,
        message: err.message,
        type: 'error'
      });
      this.loading.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.app-toolbar {
  padding-bottom: 16px;

  .upload {
    display: inline-block;
    margin-right: 10px;
  }

  .el-button-group {
    margin-right: 10px;
  }
}
</style>
