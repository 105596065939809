<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import api from "@/api/common";
export default {
  name: "App",
  data() {
    return {
      beforeUnloadTime: "",
      gapTime: "",
    };
  },

  mounted() {
    //页面监听页面刷新和关闭
    window.addEventListener("beforeunload", (e) => this.beforeunloadFn(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
  },

  destroyed() {
    //页面销毁前关闭监听
    window.removeEventListener("beforeunload", (e) => this.beforeunloadFn(e));
    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },

  methods: {
    beforeunloadFn() {
      this.beforeUnloadTime = new Date().getTime();
      console.log("this.gapTime", this.gapTime);
    },

    // 关闭窗口之后执行
    async unloadHandler() {
      this.gapTime = new Date().getTime() - this.beforeUnloadTime;
      alert(this.gapTime);
      //判断是窗口关闭还是刷新
      if (this.gapTime < 5) {
        //浏览器关闭
        //自己的操作行为
        this.loginOut();
      } else {
        console.log("刷新");
      }
    },
    async loginOut() {
      let res = await api.commonPost({ apiName: "/user/logout" });
      console.log(res);
    },
  },
};
</script>

<style lang="scss">

.font-gray{ color: #9f9d9d;}

* {
  padding: 0;
  margin: 0;
}
ul,
li {
  list-style: none;
}
p {
  padding: 0;
  margin: 0;
  line-height: 30px;
}
.clear {
  clear: both;
}
.warmingFont {
  color: #e6a23c;
}
.errorFont {
  color: #f56c6c;
}
.boldFont {
  font-weight: bold;
}
.el-table__expanded-cell {
  background-color: #fdf5e6 !important;
}
.el-table__expanded-cell:hover {
  background-color: #fdf5e6 !important;
}
.fullWidth {
  width: 100%;
}
.commonPrepend {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.grayBg{ background: #f7f7f7;}
.searchDateRange {
  margin-right: 10px;
  clear: both;
}
.searchDateRange .searchCommonLable {
  border: 1px solid #dcdfe6;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 10px;
  line-height: 30px;
  background: #f5f7fa;
  border-right: none;
  color: #909399;
  font-size: 14px;
  float: left;
}
@for $i from 1 through 200 {


  .line-h-#{$i} {
    line-height: $i + px;
  }
  .m-#{$i} {
    margin: $i + px;
  }
  .m-t-#{$i} {
    margin-top: $i + px;
  }
  .m-b-#{$i} {
    margin-bottom: $i + px;
  }
  .m-l-#{$i} {
    margin-left: $i + px;
  }
  .m-r-#{$i} {
    margin-right: $i + px;
  }
  .m-l-t-#{$i} {
    margin-left: $i + px;
    margin-top: $i + px;
  }
  .m-l-r-#{$i} {
    margin-left: $i + px;
    margin-right: $i + px;
  }
  .m-t-b-#{$i} {
    margin-top: $i + px;
    margin-bottom: $i + px;
  }

  .p-#{$i} {
    padding: $i + px;
  }
  .p-t-#{$i} {
    padding-top: $i + px;
  }
  .p-b-#{$i} {
    padding-bottom: $i + px;
  }
  .p-l-#{$i} {
    padding-left: $i + px;
  }
  .p-r-#{$i} {
    padding-right: $i + px;
  }
  .p-t-b-#{$i} {
    padding-top: $i + px;
    padding-bottom: $i + px;
  }
  .p-l-r-#{$i} {
    padding-left: $i + px;
    padding-right: $i + px;
  }

  .font-#{$i} {
    font-size: $i + px;
  }
  .radius-#{$i} {
    border-radius: $i + px;
    -webkit-border-radius: $i + px;
    -moz-border-radius: $i + px;
    -ms-border-radius: $i + px;
    -o-border-radius: $i + px;
  }
}
.commonLabel {
  display: inline-block;
  background-color: #f5f7fa;
  color: #909399;
  vertical-align: middle;
  line-height: 32px;
  height: 32px;
  padding: 0 10px;
  border: 1px solid #dcdfe6;
  border-right: none;
  position: relative;
  font-weight: normal;
  top: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 14px;
}
.el-table {
  margin-bottom: 20px;
}
.commonTop {
  margin-bottom: 10px;
}
.pagination {
  margin-top: 15px;
}

.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.el-input-group__append,
.el-input-group__prepend {
  padding: 0 10px !important;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  height: 50px;
}
.el-dialog__header {
  background: url("assets/images/formHead.png") no-repeat;
  background-size: 100% 100%;
}
.el-dialog__title {
  color: #fff;
}
.searchInput {
  width: 250px;
  margin-right: 10px;
  font-size: 14px;
  margin-bottom: 10px;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}

.search {
  display: flex;
  flex-wrap: wrap;
}
.lableName {
  line-height: 40px;
  background: #f5f7fa;
  color: #909399;
  height: 40px;
  border: 1px solid #dcdfe6;
  padding: 0 8px;
  border-right: none;
  left: 2px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
}


.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.app_con {
  background: #fff;
  margin: 20px;
  padding: 20px;
  color: #606266;
  min-height: calc(100vh - 110px);
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis--l3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.success {
  color: #67c23a;
}
.danger {
  color: #f56c6c !important;
}
.warning {
  color: #e6a23c;
}
.info {
  color: #909399;
}
.displayIB {
  display: inline-block;
}
.status-radius {
  display: inline-block;
  &::before {
    display: inline-block;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  &.font-green::before {
    background: #67c23a;
  }
  &.font-red::before {
    background: #f56c6c;
  }
}
.font-green {
  color: #67c23a;
}
.font-red {
  color: #f56c6c;
}
.font-blue {
  color: #409eff;
}
.tablePic {
  width: 60px;
  height: 60px;
  border-radius: 4px;
}
.pointer{ cursor: pointer;}
</style>
