<template>
  <div>
    <el-row v-for="(line, i) in value" :key="i">
      <el-col v-for="(prop, j) in props" :key="j" :span="4">
        <el-input
          class="input"
          v-if="prop.type == 'input'"
          v-model="line[prop.prop]"
          :placeholder="prop.placeholder"
        />
        <el-select
          class="select"
          v-else-if="prop.type == 'select'"
          v-model="line[prop.prop]"
          :placeholder="prop.placeholder"
        >
          <el-option v-for="(prop, k) in prop.options" :key="k" v-bind="prop" />
        </el-select>
        <strong v-else>{{ line }}</strong>
      </el-col>
      <el-col :span="4">
        <el-button
          size="mini"
          type="primary"
          circle
          icon="el-icon-plus"
          @click="onCreate"
        ></el-button>
        <el-button
          v-if="i > 0"
          size="mini"
          type="danger"
          circle
          icon="el-icon-minus"
          @click="onRemove(i)"
        ></el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'AppMultiLine',
  props: {
    value: Array,
    props: Array
  },
  methods: {
    onCreate() {
      this.value.push({
        commoditTypeId: '',
        commoditName: '',
        detectProjectId: '',
        quantity: ''
      });
      this.$emit('input', this.value);
    },
    onRemove(i) {
      this.value.splice(i, 1);
      this.$emit('input', this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.input {
  margin-right: 10px;
  margin-bottom: 10px;
  width: auto;
}
.select {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
